.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
  }
  .logo {
    float: left;
    width: 220px;
    height: 31px;
    // margin: 0 24px 0 0;
    // background: rgba(255, 255, 255, 0.3);
    color: white !important; 
  }
  .ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }

  body {
    background-color: #F0F2F5 !important;
  }
  
  .chat {
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .messages {
    display: flex;
    flex-direction: column;
  }
  
  .message {
    border-radius: 20px;
    padding: 8px 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
  }
  
  .yours {
    align-items: flex-start;
  }
  
  .yours .message {
    margin-right: 25%;
    background-color: #eee;
    position: relative;
  }
  
  .yours .message.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #eee;
    border-bottom-right-radius: 15px;
  }
  .yours .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
  }
  
  .mine {
    align-items: flex-end;
  }
  
  .mine .message {
    color: white;
    margin-left: 25%;
    background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    position: relative;
  }
  
  .mine .message.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
  }
  
  .mine .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-left-radius: 10px;
  }
  .order-table .ant-row > div {
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;
}

.order-table .ant-row > div > div {
    border: none;
}
.ant-card-meta.textColor > div >div {
  color: white;
}
@primary-color: #EA4522;